import React, { useContext, useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import PageWrapper from "../components/PageWrapper";
import { Select } from "../components/Core";
import RichTextEditor from "../components/RichTextEditor";
import { AuthContext } from "../context/AuthContext";
import { DataContext } from "../context/DataContext";
import { login } from "../utils/utilities";
import { validation } from "../utils/validation";
import notify from "../utils/notify";
import { updateJob } from "../api";
import { useWalletSelector } from "../context/WalletSelectorContext";
import { utils } from "near-api-js";

const PostJob = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [otherCategory, setOtherCategory] = useState(false);
  const [otherCategoryCheck, setOtherCategoryCheck] = useState(false);
  const { selector, accountId } = useWalletSelector()
  const [companies, setCompanies] = useState([]);
  const {
    register,
    errors,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
  } = useForm();

  const watchJobType = watch("jobtype");
  const watchCategory = watch("category");
  const watchSubCategory = watch("subcategory");

  if (watchSubCategory != null || watchSubCategory != undefined) {
    if (watchSubCategory.value == "other" && otherCategoryCheck == false) {
      setOtherCategory(true);
      setOtherCategoryCheck(true);
    }
    if (otherCategoryCheck == true) {
      if (watchSubCategory.value != "other") {
        setOtherCategory(false);
        setOtherCategoryCheck(false);
      }
    }
  }

  const { auth, getUser } = useContext(AuthContext);
  const {
    categories,
    currencies,
    jobTypes,
    experienceLevels,
    postJobFee,
    postJob,
    allSkills,
    getCategories,
    getRevenueModel,
    getSkills,
    getAllClientList,
  } = useContext(DataContext);

  const params = new URLSearchParams(location.search);
  let publicKey = params.get("public_key");
  let transactionHashes = params.get("transactionHashes");

  const loadSettingsAndCompanies = async () => {
    await getRevenueModel();
    await getCategories();
    await getSkills();
    await fetchClientList();
  };

  const fetchUserData = async (accountId) => {
    const response = await getUser(accountId);
    if (!response) {
      navigate("/", { replace: true });
    } else {
      await loadSettingsAndCompanies();
      setLoading(false);
    }
  };

  const addTxToJob = async (jobId, txHash) => {
    try {
      await updateJob(jobId, "transaction", txHash);
      notify("Job posted successfully!", "success");
      localStorage.removeItem("payload");
      localStorage.removeItem("jobId");
      setTimeout(
        () => navigate(`/job-details/${jobId}`, { state: { id: jobId } }),
        1000
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const fetchClientList = async () => {
    let companyList = [];
    const response = await getAllClientList();
    await Promise.all(
      response.map((client) => {
        if (client.status === "active") {
          companyList.push({
            label: client.company,
            value: client.account_id,
            ...client,
          });
        }
      })
    );
    setCompanies(companyList);
  };

  const postJobSelector = async (amount, accountId) => {
    try {
      let amountInYocto;
      if (process.env.GATSBY_ADMIN_MANAGERS.includes(accountId)) {
        amountInYocto = utils.format.parseNearAmount("0.01");
      } else {
        amountInYocto = utils.format.parseNearAmount(amount.toString());
      }
      const wallet = await selector.wallet()
      const response = await wallet
        .signAndSendTransaction({
          signerId: accountId,
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "post_job",
                args: { receiver: process.env.GATSBY_ADMIN_WALLET },
                gas: "300000000000000",
                deposit: amountInYocto,
              },
            },
          ],
        })
      if (response) {
        window.location.href = `/post-job?transactionHashes=${response.transaction.hash}`;
      }
    } catch (err) {
      console.log("Failed to post job");
      notify("Failed to post job")
      setSubmitting(false);
      setLoading(false);
    };
  }


  useEffect(() => {
    if (accountId && publicKey) {
      fetchUserData(accountId);
    } else if (transactionHashes) {
      const jobId = localStorage.getItem("jobId");
      if (jobId) {
        addTxToJob(jobId, transactionHashes);
      }
    } else {
      loadSettingsAndCompanies();
      setLoading(false);
    }
    return () => {
      setSubmitting(false);
      setLoading(true);
    };
  }, []);

  const onEditorStateChange = (data) => {
    setValue("description", data);
  };

  const onSubmit = async (data) => {

    const currentDate = new Date();
    const expiryDate = currentDate.setMonth(currentDate.getMonth() + 3)

    try {
      if (auth && auth?.talent) {
        notify(
          "Only clients can post jobs. Create a Client account to continue"
        );
      } else if (!auth) {
        await login(location.pathname, location.pathname);
      } else {
        setSubmitting(true);
        const client = auth?.admin
          ? data.company.account_id
          : auth.client.account_id;
        const company = auth?.admin
          ? data.company.company
          : auth.client.company;
        const logo = auth?.admin ? data.company.imageUrl : auth.client.imageUrl;
        const category = data.category.label;
        const subcategory = data.subcategory.label;
        const experience = data.experience.label;
        const currency = data.currency.label;
        const jobtype = data.jobtype.label;
        const skills = data.skills.map(({ label }) => label);
        const rate = data?.rate ? parseInt(data.rate) : null;
        const salary = data?.salary ? parseInt(data.salary) : null;
        const featured = data?.featured || false;

        const formData = _.omit(data, [
          "category",
          "subcategory",
          "experience",
          "skills",
          "rate",
          "salary",
          "currency",
          "terms",
          "jobtype",
          "company",
        ]);
        const payload = {
          client,
          company,
          logo,
          category,
          subcategory,
          experience,
          skills,
          rate,
          salary,
          currency,
          jobtype,
          featured,
          expiryDate: new Date(expiryDate).getTime(),
          ...formData,
        };

        if (auth) {
          reset();
          // Save job in db and return unique job id
          const jobId = await postJob(client, payload);
          localStorage.setItem("jobId", jobId);
          const walletId = auth?.admin
            ? auth?.admin.account_id
            : auth.client.account_id;
          await postJobSelector(postJobFee, walletId);
        }

      }
    } catch (error) {
      console.log(error);
      notify(error.message);
      setSubmitting(false);
    }

  };

  return (
    <>
      <PageWrapper headerConfig={auth ? { button: "profile" } : null}>
        <div className="bg-default-2 pt-22 pt-lg-25 pb-13 pb-xxl-32">
          <div className="container">
            {/* <!-- back Button --> */}
            <div className="row justify-content-center">
              <div className="col-12 dark-mode-texts">
                <div className="mb-9">
                  {loading ? (
                    <Skeleton
                      height={50}
                      containerClassName="w-40"
                      className="w-40"
                    />
                  ) : (
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                      }}
                      className="d-flex align-items-center ml-4"
                    >
                      {" "}
                      <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                      <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                        Back
                      </span>
                    </Link>
                  )}
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-12">
                {loading ? (
                  <Skeleton
                    height={100}
                    containerClassName="text-center w-100"
                    className="w-70"
                  />
                ) : (
                  <h3 className="card-title font-size-8 font-weight-bold text-center">
                    Hire the best talents for your next project
                  </h3>
                )}
              </div>
            </div>

            <div className="row justify-content-center pt-12">
              <div className="col-12 col-xxl-7 col-lg-7 col-md-7">
                {!auth ? (
                  <div>
                    {loading ? (
                      <Skeleton
                        height={50}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    ) : (
                      <button
                        type="link"
                        className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase focus-reset"
                        onClick={(e) => {
                          e.preventDefault();
                          login(location.pathname, location.pathname);
                        }}
                      >
                        Login as a Client to post a job
                      </button>
                    )}
                  </div>
                ) : (
                  <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    {loading ? (
                      <div className="form-group">
                        <Skeleton
                          height={100}
                          containerClassName="text-center w-100"
                          className="w-70"
                        />
                      </div>
                    ) : auth?.admin ? (
                      <div className="form-group">
                        <label
                          htmlFor="company"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Company Name*
                        </label>
                        <Controller
                          name="company"
                          control={control}
                          defaultValue=""
                          rules={validation.company}
                          as={
                            <Select
                              options={companies}
                              className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                              border={false}
                            />
                          }
                        />
                        <p className="mb-2">
                          <Link
                            to="/dashboard-admin-create-client"
                            className="font-size-3 font-weight-semibold text-green"
                          >
                            Can't find the company name? Create one
                          </Link>
                        </p>
                        <span className="text-danger small">
                          {errors?.company?.message || ""}
                        </span>
                      </div>
                    ) : null}

                    {loading ? (
                      <div className="form-group">
                        <Skeleton
                          height={100}
                          containerClassName="text-center w-100"
                          className="w-70"
                        />
                      </div>
                    ) : (
                      <div className="form-group">
                        <label
                          htmlFor="position"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Position*
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Blockchain Developer"
                          id="position"
                          name="position"
                          ref={register(validation.position)}
                        />
                        <span className="text-danger small">
                          {errors?.position?.message || ""}
                        </span>
                      </div>
                    )}

                    {loading ? (
                      <div className="form-group">
                        <Skeleton
                          height={400}
                          containerClassName="text-center w-100"
                          className="w-70"
                        />
                      </div>
                    ) : (
                      <div className="form-group">
                        <label
                          htmlFor="description"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Description*
                        </label>
                        <Controller
                          name="description"
                          control={control}
                          defaultValue=""
                          rules={validation.description}
                          as={
                            <RichTextEditor
                              onEditorStateChange={onEditorStateChange}
                            />
                          }
                        />
                        <span className="text-danger small">
                          {errors?.description?.message || ""}
                        </span>
                      </div>
                    )}

                    {loading ? (
                      <div className="form-group">
                        <Skeleton
                          height={100}
                          containerClassName="text-center w-100"
                          className="w-70"
                        />
                      </div>
                    ) : (
                      <div className="form-group">
                        <label
                          htmlFor="category"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Category*
                        </label>
                        <Controller
                          name="category"
                          control={control}
                          defaultValue=""
                          rules={validation.category}
                          as={
                            <Select
                              options={categories}
                              className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                              border={false}
                            />
                          }
                        />
                        <span className="text-danger small">
                          {errors?.category?.message || ""}
                        </span>
                      </div>
                    )}

                    {loading ? (
                      <div className="form-group">
                        <Skeleton
                          height={100}
                          containerClassName="text-center w-100"
                          className="w-70"
                        />
                      </div>
                    ) : (
                      <div className="form-group">
                        <label
                          htmlFor="subcategory"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Subcategory*
                        </label>
                        <Controller
                          name="subcategory"
                          control={control}
                          defaultValue=""
                          rules={validation.subcategory}
                          as={
                            <Select
                              options={
                                watchCategory?.value
                                  ? categories.filter((category) => {
                                    if (
                                      category.value === watchCategory?.value
                                    ) {
                                      return category.subcategories;
                                    }
                                  })[0].subcategories
                                  : []
                              }
                              className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                              border={false}
                            />
                          }
                        />
                        <span className="text-danger small">
                          {errors?.subcategory?.message || ""}
                        </span>
                      </div>
                    )}

                    {otherCategory ? (
                      <div className="form-group">
                        <label
                          htmlFor="otherCategory"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Other Category*
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          id="otherCategory"
                          name="otherCategory"
                          ref={register(validation.otherCategory)}
                        />
                        <span className="text-danger small">
                          {errors?.otherCategory?.message || ""}
                        </span>
                      </div>
                    ) : null}

                    {loading ? (
                      <div className="form-group">
                        <Skeleton
                          height={100}
                          containerClassName="text-center w-100"
                          className="w-70"
                        />
                      </div>
                    ) : (
                      <div className="form-group">
                        <label
                          htmlFor="jobtype"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Job Type*
                        </label>
                        <Controller
                          name="jobtype"
                          control={control}
                          defaultValue=""
                          rules={validation.jobtype}
                          as={
                            <Select
                              options={jobTypes}
                              className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                              border={false}
                              id="jobtype"
                            />
                          }
                        />
                        <span className="text-danger small">
                          {errors?.jobtype?.message || ""}
                        </span>
                      </div>
                    )}

                    {loading ? (
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    ) : (
                      <h3 className="card-title font-size-6 font-weight-bold">
                        Talent Preference
                      </h3>
                    )}
                    <hr />

                    {loading ? (
                      <div className="form-group">
                        <Skeleton
                          height={100}
                          containerClassName="text-center w-100"
                          className="w-70"
                        />
                      </div>
                    ) : watchJobType?.value == "full-time" ? (
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="form-group">
                          <label
                            htmlFor="salary"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Salary*
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="salary"
                            name="salary"
                            placeholder="Monthly Salary"
                            ref={register(validation.salary)}
                          />
                          <span className="text-danger small">
                            {errors?.salary?.message || ""}
                          </span>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="currency"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Currency*
                          </label>
                          <Controller
                            name="currency"
                            control={control}
                            defaultValue=""
                            rules={validation.currency}
                            as={
                              <Select
                                options={currencies}
                                className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                                border={false}
                                id="currency"
                              />
                            }
                          />
                          <span className="text-danger small">
                            {errors?.currency?.message || ""}
                          </span>
                        </div>
                      </div>
                    ) : watchJobType?.value == "freelancing" ? (
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="form-group">
                          <label
                            htmlFor="rate"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Hourly Rate*
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="rate"
                            name="rate"
                            placeholder="Hourly Rate"
                            ref={register(validation.rate)}
                          />
                          <span className="text-danger small">
                            {errors?.rate?.message || ""}
                          </span>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="currency"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Currency*
                          </label>
                          <Controller
                            name="currency"
                            control={control}
                            defaultValue=""
                            rules={validation.currency}
                            as={
                              <Select
                                options={currencies}
                                className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                                border={false}
                                id="currency"
                              />
                            }
                          />
                          <span className="text-danger small">
                            {errors?.currency?.message || ""}
                          </span>
                        </div>
                      </div>
                    ) : null}

                    {loading ? (
                      <div className="form-group">
                        <Skeleton
                          height={100}
                          containerClassName="text-center w-100"
                          className="w-70"
                        />
                      </div>
                    ) : (
                      <div className="form-group">
                        <label
                          htmlFor="experience"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Experience Level*
                        </label>
                        <Controller
                          name="experience"
                          control={control}
                          defaultValue=""
                          rules={validation.experience}
                          as={
                            <Select
                              options={experienceLevels}
                              className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                              border={false}
                              id="experience"
                            />
                          }
                        />
                        <span className="text-danger small">
                          {errors?.experience?.message || ""}
                        </span>
                      </div>
                    )}

                    {loading ? (
                      <div className="form-group">
                        <Skeleton
                          height={100}
                          containerClassName="text-center w-100"
                          className="w-70"
                        />
                      </div>
                    ) : (
                      <div className="form-group">
                        <label
                          htmlFor="skills"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Skills Needed*
                        </label>
                        <Controller
                          name="skills"
                          control={control}
                          defaultValue=""
                          rules={validation.skills}
                          as={
                            <Select
                              options={allSkills}
                              className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control pt-8 pb-24 pb-sm-22 border-bottom-0 basic-multi-select"
                              classNamePrefix="select"
                              border={false}
                              isMulti
                            />
                          }
                        />
                        <span className="text-danger small">
                          {errors?.skills?.message || ""}
                        </span>
                      </div>
                    )}

                    {loading ? (
                      <div className="form-group">
                        <Skeleton
                          height={100}
                          containerClassName="text-center w-100"
                          className="w-70"
                        />
                      </div>
                    ) : (
                      <div className="form-group">
                        <label
                          htmlFor="location"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Location*
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="location"
                          name="location"
                          placeholder="Remote or City"
                          ref={register(validation.location)}
                        />
                        <span className="text-danger small">
                          {errors?.location?.message || ""}
                        </span>
                      </div>
                    )}

                    {loading ? (
                      <div className="form-group">
                        <Skeleton
                          height={100}
                          containerClassName="text-center w-100"
                          className="w-70"
                        />
                      </div>
                    ) : auth?.admin ? (
                      <div className="form-group">
                        <label
                          htmlFor="applicationurl"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          External Application URL
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Application URL"
                          id="applicationurl"
                          name="applicationurl"
                          ref={register(validation.url)}
                        />
                        <span className="text-danger small">
                          {errors?.applicationurl?.message || ""}
                        </span>
                      </div>
                    ) : null}

                    {loading ? (
                      <div className="form-group">
                        <Skeleton
                          height={100}
                          containerClassName="text-center w-100"
                          className="w-70"
                        />
                      </div>
                    ) : auth?.admin ? (
                      <div className="form-group">
                        <label
                          htmlFor="featured"
                          className="gr-check-input d-flex  mr-3"
                        >
                          <input
                            className="d-none"
                            type="checkbox"
                            id="featured"
                            name="featured"
                            ref={register(validation.featured)}
                          />
                          <span className="checkbox mr-5"></span>
                          <span className="font-size-4 text-black-2 mb-0 line-height-reset mb-1 d-block">
                            Featured Job
                          </span>
                        </label>
                        <span className="text-danger small">
                          {errors?.featured?.message || ""}
                        </span>
                      </div>
                    ) : null}

                    {loading ? (
                      <div className="form-group">
                        <Skeleton
                          height={100}
                          containerClassName="text-center w-100"
                          className="w-70"
                        />
                      </div>
                    ) : (
                      <div className="form-group">
                        <label
                          htmlFor="terms"
                          className="gr-check-input d-flex  mr-3"
                        >
                          <input
                            className="d-none"
                            type="checkbox"
                            id="terms"
                            name="terms"
                            ref={register(validation.terms)}
                          />
                          <span className="checkbox mr-5"></span>
                          <span className="font-size-3 mb-0 line-height-reset mb-1 d-block">
                            Yes, I understand and agree to the DevShop Terms of
                            Service, including the User Agreement and Privacy
                            Policy
                          </span>
                        </label>
                        <span className="text-danger small">
                          {errors?.terms?.message || ""}
                        </span>
                      </div>
                    )}

                    <div className="form-group mb-8">
                      {loading ? (
                        <Skeleton
                          height={100}
                          containerClassName="text-center w-100"
                          className="w-70"
                        />
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase focus-reset"
                          disabled={submitting || loading ? true : false}
                        >
                          {submitting
                            ? "Processing..."
                            : postJobFee != 0
                              ? `Pay ${auth?.admin ? "0.01" : postJobFee} NEAR`
                              : "Post Job"}
                        </button>
                      )}
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default PostJob;
